<template>
  <div>
    <div class="main-title">测评列表</div>
    <div class="content-list-page page-content">
      <div class="padding-t-sm padding-b-sm">
        <span class="margin-l-sm fl-l" label="名称">
          <el-input
            v-model="searchData.name"
            placeholder="请输入名称"
          ></el-input>
        </span>

        <span class="margin-l-sm fl-l">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="fl-r">
          <router-link class="router-link" to="/evaluation/add">
            <el-button size="small" type="primary">新增</el-button>
          </router-link>
        </span>
      </div>
      <div class="table-content">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :cell-style="{ textAlign: 'center' }"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          highlight-current-row
          style="width: 100%; background: #fff"
        >
          <el-table-column align="center" label="ID" property="id" width="80">
          </el-table-column>
          <el-table-column align="center" label="名称" property="name">
          </el-table-column>
          <el-table-column
            align="center"
            label="创建时间"
            property="created_at"
          >
          </el-table-column>
          <el-table-column
            align="center"
            label="更新时间"
            property="updated_at"
          >
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <span style="margin-right: 5px">
                <el-link
                  type="primary"
                  @click="$router.push('/evaluation/details/' + scope.row.id)"
                  >详情</el-link
                >
              </span>
              <span style="margin-right: 5px">
                <el-link type="primary" @click="entrance(scope.row, 'qrcode')"
                  >入口二维码</el-link
                >
              </span>
              <span style="margin-right: 5px">
                <el-link type="primary" @click="entrance(scope.row, 'wxacode')"
                  >小程序入口二维码</el-link
                >
              </span>
              <span style="margin-right: 5px">
                <el-link
                  type="primary"
                  @click="$router.push('/evaluation/edit/' + scope.row.id)"
                  >编辑</el-link
                >
              </span>
              <span style="margin-right: 5px">
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-l">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" append-to-body width="250px">
      <div v-loading="image" class="image">
        <img
          :src="imageUrl + '/' + dialogImageUrl"
          alt=""
          height="200px"
          width="200px"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "@/js/config";
export default {
  name: "list",
  data() {
    return {
      searchData: {},
      pageSize: 10,
      page: 1,
      total: 0,
      loading: false,
      tableData: [],
      dialogImageUrl: "",
      imageUrl: config.baseUrl,

      dialogVisible: false,
      image: false,
    };
  },
  mounted() {
    this.getList();
  },

  methods: {
    ...mapActions("evaluation", [
      "evaluationList",
      "delEvaluation",
      "getEvaluationQrcode",
    ]),
    async entrance(row, type) {
      let H5 = config.H5;
      this.dialogVisible = true;
      this.image = true;
      let form = {
        type,
        id: row.id,
        page: "packageProduct/appraise/index",
      };
      const { data } = await this.getEvaluationQrcode(form);
      this.dialogImageUrl = data.file_path;
      this.image = false;
      console.log(data);
    },
    async deleted(row) {
      const { res_info } = await this.delEvaluation({ id: row.id });
      if (res_info != "ok") return;
      this.$message.success("删除成功！");
      this.getList();
    },
    search() {
      this.page = 1;
      this.getList();
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let form = {
        ...this.searchData,
        pageSize: this.pageSize,
        page: this.page,
      };
      const { data } = await this.evaluationList(form);
      this.total = data.total;
      this.tableData = data.list;
      this.loading = false;
      console.log(data);
    },
  },
};
</script>

<style scoped>
.image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
